<template>
  <div id="app">
    <component :is="componentFile"></component>
  </div>
</template>

<script>
import AOS from "aos";

export default {
  name: 'App',
  components: {},
  computed: {
    componentFile() {
      if(this.devUrls.includes(window.location.origin)) {
        return () => import(`./theme-components/ThemeDefault.vue`);
      }

      let file;

      if (this.defaultUrls.includes(window.location.origin))
        switch (window.location.pathname) {
          case "/app":
            file = "ThemeDefault";
            break; 
          case "/kalkulacka/corvusmalacky":
            file = "themeCorvusMalacky";
            break; 
          case "/kalkulacka/malehliny":
            file = "themeMaleHliny";
            break;  
          case "/kalkulacka/novatulipa":
            file = "themeNovaTulipa";
            break;
          default:
            file = "ThemeDefaultOnlyChat";
        }
      else if (window.location.origin.includes("annapark.sk"))
        file = "ThemeAnnaPark";

      else if (window.location.origin.includes("1solution"))
        file = "ThemeSolution";

        else if (window.location.origin.includes("aleksincevinice.sk"))
      file = "ThemeAleksincevinice";

      else if (window.location.origin.includes("https://app.osobnimakleri.sk"))
        file = "ThemeOm";

      else if (window.location.origin.includes("https://www-adamkudelas-sk.filesusr.com"))
        file = "ThemeAdamKudelas";

      else if (window.location.origin.includes("https://www.corvusmalacky.sk"))
        file = "themeCorvusMalacky";

      else if (window.location.origin.includes("corvusmalacky"))
        file = "themeCorvusMalacky";

      else if (window.location.origin.includes("malehliny.sk"))
        file = "themeMaleHliny";

      else if (window.location.origin.includes("novatulipa"))
        file = "themeNovaTulipa";

      return () => import(`./theme-components/${file}.vue`);
    }
  },
  created() {
    //webpack variable
    window.chunkURL = "https://analyza.bankari.sk/";

    AOS.init({
      duration: 1000,
      offset: 0
    });

    if (!this.$store.state.access) {
      const token = localStorage.getItem("token");
      const refresh = localStorage.getItem("refresh");
      const clientId = localStorage.getItem("clientId");

      if (token)
        this.$store.commit("setTokens", {
          access: token,
          refresh: refresh
        });

      if (clientId)
        this.$store.commit("setClientId", clientId);
    }
  }
}
</script>

<style lang="scss">
</style>
